"use client";
import { useEffect, useMemo, useState } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import ContainerStyle from "@/src/components/Global_Components/GridSystem/ContainerStyle";
import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import BlogPostPreviewCard from "@/src/components/Contentful_Components/BlogPostPreviewCard/BlogPostPreviewCard";
import Icon from "@/src/components/Global_Components/Icon/Icon";
import ContentfulImage from "@/src/lib/contentful-image";
import SubFilter from "@/src/components/Global_Components/SubFilter/SubFilter";
import { category } from "@/utils/category";
import { BlogEntryType } from "@/src/lib/blogListing";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";
import * as Styles from "./styles";

interface BlogListingProps {
  blogList: any;
  translations: any;
  lang: string;
  page: any;
  postCount: number;
  pageCount: number;
  subHeading?: any;
  isCategory: boolean;
}

const BlogListing: React.FC<BlogListingProps> = ({
  blogList,
  translations,
  lang,
  page,
  postCount,
  pageCount,
  subHeading,
  isCategory,
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const router = useRouter();
  const path = usePathname();
  const params = useSearchParams();

  const featuredBlog = useMemo(() => {
    return currentPage === 1 && blogList.length && !isCategory
      ? blogList[0]
      : null;
  }, [currentPage, blogList, isCategory]);

  const displayedBlogList = useMemo(() => {
    return currentPage === 1 && blogList.length && !isCategory
      ? blogList.slice(1, postCount)
      : blogList;
  }, [currentPage, blogList, postCount, isCategory]);

  // Initialize currentPage based on URL query parameter
  useEffect(() => {
    const pageParam = params.get("page");
    if (pageParam) {
      setCurrentPage(parseInt(pageParam, 10));
    } else {
      setCurrentPage(1);
    }
  }, [params]);

  useEffect(() => {
    if (currentPage != page) {
      const newParams = new URLSearchParams(params);

      if (currentPage === 1) {
        newParams.delete("page"); // Unset the 'page' search parameter
      } else {
        newParams.set("page", currentPage.toString());
      }

      const newUrl = `${path}?${newParams.toString()}`;
      router.push(newUrl);
    }
  }, [currentPage, params, path, router, page]);

  const placeholders = [
    "WebBackgrounds_Triangles_Blue.png",
    "WebBackgrounds_Triangles_Cyan.png",
    "WebBackgrounds_Triangles_Yellow.png",
    "WebBackgrounds_Triangles_Orange.png",
  ];

  const placeholdImg = url => {
    if (url && url.length > 0) {
      return url;
    }

    return "/img/Blog Placeholder.png";
  };

  return (
    <>
      <header style={{ background: "#000", color: "#fff" }}>
        <ContainerStyle
          className="container"
          style={{ flexDirection: "column" }}
        >
          <RowStyle>
            {!isCategory && (
              <div>
                <Styles.BlogHeading color="#fff">
                  <h1>{translations.Blog}</h1>
                </Styles.BlogHeading>
              </div>
            )}
            {isCategory && (
              <Styles.BlogHeading style={{ paddingTop: "40px" }}>
                <Styles.SubHeading color="#fff" style={{ color: "#fff" }}>
                  {translations.Blog}
                </Styles.SubHeading>
                <h1>{translations[`${subHeading}`] || subHeading}</h1>
              </Styles.BlogHeading>
            )}
          </RowStyle>
        </ContainerStyle>

        <SubFilter
          style={{ position: "relative" }}
          filters={category}
          lang={lang}
          translations={translations}
        />
      </header>

      {currentPage == 1 && featuredBlog && (
        <FeaturedBlogArticle article={featuredBlog} lang={lang} />
      )}

      <ContainerStyle>
        <Styles.PostGrid
          style={{ paddingTop: currentPage == 1 ? "" : "100px" }}
        >
          <RowStyle className={` ${isCategory ? "category-top" : ""}`}>
            {displayedBlogList &&
              displayedBlogList.map((data, index) => {
                const itemSlug = data.slug;
                const linkPath = `/${lang}/resources/blog/${itemSlug}/`;
                return (
                  <ColStyle key={`content_item_${index}`} lg={4} md={6} sm={12}>
                    <BlogPostPreviewCard
                      callToActionLink={linkPath}
                      author={data.author.fields.name}
                      published={data.publishedAt}
                      title={data.title}
                      imageSource={
                        data
                          ? placeholdImg(data.mainImageUrl)
                          : placeholdImg(data.fields.mainImageUrl)
                      }
                      tags={data.tags}
                      lang={lang}
                      image={data.mainImage}
                    />
                  </ColStyle>
                );
              })}
          </RowStyle>
        </Styles.PostGrid>
        {pageCount != 0 && (
          <Styles.ButtonWrapper>
            {currentPage > 1 && (
              <Styles.LoadMoreButton>
                <button
                  disabled={currentPage === 1}
                  className={` ${currentPage > 1 ? "" : "inactive"}`}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    if (window) {
                      window.scroll({
                        top: 0,
                        left: 0,
                      });
                    }
                  }}
                >
                  Previous
                </button>
              </Styles.LoadMoreButton>
            )}
            {currentPage != pageCount && (
              <Styles.LoadMoreButton>
                <button
                  disabled={blogList.length < 21}
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                    if (window) {
                      window.scroll({
                        top: 0,
                        left: 0,
                      });
                    }
                  }}
                >
                  Next
                </button>
              </Styles.LoadMoreButton>
            )}
          </Styles.ButtonWrapper>
        )}
      </ContainerStyle>
    </>
  );
};

interface FeaturedBlogArticleType {
  article: BlogEntryType | null;
  lang: string;
  imageDescription?: string;
  textColor?: string;
}

const FeaturedBlogArticle = ({
  article,
  lang,
  imageDescription = "",
  textColor = "#000",
}: FeaturedBlogArticleType) => {
  if (!article) {
    return null;
  }

  return (
    <Styles.FeaturedBlogArticle>
      <section>
        {article.mainImageUrl && !article.mainImage && (
          <ContentfulImage
            alt={imageDescription}
            width={400}
            height={400}
            quality={100}
            priority={true}
            src={article.mainImageUrl}
          />
        )}
        {article.mainImage && (
          <ContentfulImage
            alt={article.mainImage?.fields?.title || ""}
            width={400}
            height={400}
            quality={100}
            priority={true}
            src={article.mainImage?.fields?.file?.url}
          />
        )}
        <div className="featured-article__text-section">
          <div>
            <h4>{article.title}</h4>
            <p>{article.description}</p>
          </div>
          <a
            href={`/${lang}/resources/blog/${article.slug}/`}
            className="featured-article__text-section--cta"
          >
            <Icon iconColor={"#000"} className="icon-arrow" iconImage="arrow" />
            <p>Read the blog</p>
          </a>
        </div>
      </section>
      <div className="gradient-line" />
    </Styles.FeaturedBlogArticle>
  );
};

export default BlogListing;
