import React from "react";
import * as Styles from "./SubFilterStyles";
import Link from "next/link";

const SubFilter = ({ filters, lang, style, translations }) => {
  return (
    <Styles.Container style={style}>
      <Styles.FilterWrapper>
        <Styles.CheckBoxHolder tabIndex={10}>
          <Link prefetch href={`/${lang}/resources/blog/`}>
            <h5>{translations.Home}</h5>
          </Link>
        </Styles.CheckBoxHolder>
        {filters.map(
          (
            filterObject: { path: any; title: string },
            index: React.Key | null | undefined
          ) => {
            return (
              <Styles.CheckBoxHolder tabIndex={10} key={index}>
                <Link prefetch href={`/${lang}/${filterObject.path}/`}>
                  <h5>{translations[filterObject.title]}</h5>
                </Link>
              </Styles.CheckBoxHolder>
            );
          }
        )}
      </Styles.FilterWrapper>
    </Styles.Container>
  );
};
export default SubFilter;
