import styled from "styled-components";

export const BlogHeading = styled.div`
  border-left-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to top, #4ea9d1, #aa6c7e, #f76531, #efd900) 0 0
    0 100%;
  color: #fff;
  margin-top: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  @media only screen and (max-width: 1279px) {
  }
  h1 {
    font-family: "Agrandir", sans-serif;
    text-transform: uppercase;
    font-size: 50px;
    float: none;
    margin: 0;
    padding-top: 5px;
  }
  @media only screen and (max-width: 1279px) {
    margin-left: 30px;
    margin-top: 40px;
    h1 {
      font-size: 40px;
    }
  }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
    }
  }
`;
export const ButtonWrapper = styled.div`
  text-align: center;
`;

export const LoadMoreButton = styled.div`
  margin: 0 auto 30px;
  width: 20%;
  display: inline-block;
  button {
    font-family: "Agrandir", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    color: #000;
    padding: 17px 25px;
    background-color: transparent;
    border: 2px #000 solid;
    border-radius: 6px;
    box-sizing: border-box;
    margin: auto;
    &:hover {
      background: #000;
      color: #fff;
      cursor: pointer;
    }
    &:disabled {
      cursor: default;
      background: #cccccc;
      border: 2px #cccccc solid;
      color: #000000;
      &:hover {
        background: #cccccc;
        color: #000000;
      }
    }
  }
`;

export const SubHeading = styled.div`
  font-family: "Agrandir", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  transform: translateY(-20px);
  position: absolute;
  @media only screen and (max-width: 1279px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const PostGrid = styled.section`
  span {
    margin-top: 0;
  }
  .author-date {
    margin: 20px 0;
  }

  .category-top {
    margin-top: 5em;
  }
`;

export const FeaturedBlogArticle = styled.div`
  section {
    display: flex;
    flex-direction: row;
    gap: 40px;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;

    & h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
    }

    & img {
      width: 366px;
      height: 230px;
      object-fit: cover;
    }

    & .featured-article__text-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      & h4 {
        font-size: 27px;
        padding-bottom: 8px;
      }

      & p {
        font-size: 18px;
        line-height: 24px;
        width: 75%;
      }

      &--cta {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: baseline;

        & p {
          font-weight: 700;
        }
      }
    }
  }

  @media only screen and (max-width: 1279px) {
    section {
      padding-left: 30px;
      padding-right: 30px;
      gap: 20px;
    }
    & .featured-article__text-section {
      & h4 {
        font-size: 24px !important;
      }

      & p {
        font-size: 16px !important;
        line-height: 20px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    section {
      flex-direction: column;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & .featured-article__text-section {
        & h4 {
          font-size: 20px !important;
        }

        & p {
          width: 100%;
        }
      }
    }
  }
  .gradient-line {
    width: 100%;
    height: 2px;
    background: linear-gradient(to left, #4ea9d1, #aa6c7e, #f76531, #efd900);
    margin-bottom: 40px;
  }
`;
