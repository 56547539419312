import { BREAK_POINTS } from "@/src/lib/breakPoints";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: sticky;
  background-color: #474857;
  top: -1px;
  z-index: 2;
  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    white-space: nowrap;
  }
`;

export const FilterWrapper = styled.div`
  margin: 60px auto 0;
  max-width: 1280px;
  width: 100%;
  background: transparent;
  display: flex;

  @media only screen and (max-width: 1279px) {
    padding-left: 20px;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    border-bottom: none;
    display: flex;
    flex-wrap: wrap;
  }
  h3 {
    width: 100%;
    padding: 20px 0;
    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      display: none;
    }
  }
`;

export const CheckBoxHolder = styled.div`
  display: inline-block;
  position: relative;
  outline: none;
  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    outline: none;
    text-transform: uppercase;
    padding: 20px 12px;
    display: inline-block;
    transition:
      background 0.3s,
      color 0.3s;
    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      padding: 17px !important;
    }
    @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
      display: inline-block;
      padding: 0;
      border: none;
    }
    &:hover,
    &.active {
      cursor: pointer;
      background: #fff;
      color: #000;
    }
  }
`;
