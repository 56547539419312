export const category = [
  {
    id: 6,
    nameId: "virtualevents",
    url: "virtual-events",
    path: "resources/blog/virtual-events",
    name: "Virtual Events",
    adminName: "Virtual Events",
    title: "Virtual Events",
    languages: ["es", "en", "fr", "de", "ja", "ko"],
  },
  {
    id: 1,
    nameId: "media",
    url: "media-and-monetization",
    path: "resources/blog/media-and-monetization",
    name: "Media",
    adminName: "Media",
    title: "Media",
    languages: ["es", "en", "fr", "de", "ja", "ko"],
  },
  {
    id: 2,
    nameId: "marketing",
    url: "digital-marketing",
    path: "resources/blog/digital-marketing",
    name: "Marketing",
    adminName: "Marketing",
    title: "Marketing",
    languages: ["es", "en", "fr", "de", "ja", "ko"],
  },
  {
    id: 3,
    nameId: "brightcove",
    url: "brightcove",
    path: "resources/blog/brightcove",
    name: "Brightcove",
    adminName: "Brightcove",
    title: "Brightcove News",
    languages: ["es", "en", "fr", "de", "ja", "ko"],
  },
  {
    id: 4,
    nameId: "techtalk",
    url: "tech-talk",
    path: "resources/blog/tech-talk",
    name: "TechTalk",
    adminName: "Developer",
    title: "Tech Talk",
    languages: ["es", "en", "fr", "de", "ja", "ko"],
  },
  {
    id: 5,
    nameId: "featuresupdates",
    url: "features-and-updates",
    path: "resources/blog/features-and-updates",
    name: "Features",
    adminName: "Features & Updates",
    title: "Features & Updates",
    languages: ["es", "en", "fr", "de", "ja", "ko"],
  },
];
